import moment from "moment";
import { fontSizes } from "./constants";
import axios from "axios";
import { Timestamp } from "firebase/firestore";

export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export const em = (fontSize: number, baseSize: number = fontSizes.p.default) => {
  return `${fontSize / baseSize}em`;
};

export const emailValidator = (str: string) => {
  if (!str) return false;

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(str);
};

export const cleanedVimeoURL = (url: string) => {
  return `${replaceString(
    "https://vimeo.com/",
    "https://player.vimeo.com/video/",
    url,
  )}?background=0&autoplay=1&loop=0&byline=0&title=0&volume=1`;
};

function replaceString(oldS: string, newS: string, fullS: string) {
  return fullS.split(oldS).join(newS);
}

export function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function setCookie(cname: string, cvalue: string, exhours: number) {
  let d = new Date();
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookieValue = (cname: string) => {
  if (isBrowser() === false) return false;
  const cookieString = decodeURIComponent(document.cookie);
  const cookieArray = cookieString.split(";");

  const foundCookie = cookieArray.find(cookie => {
    return cookie.trim().startsWith(`${cname}=`);
  });

  return foundCookie ? true : false;
};

export function add30Days(inputDate: string) {
  // Parse inputDate using Moment.js
  const formatedNumber = Number(inputDate);
  let date = moment(formatedNumber);

  // Check if date is valid
  if (!date.isValid()) {
    return "Invalid date";
  }

  // Add 30 days to the date
  date.add(30, "days");

  // Return the timestamp
  return date.valueOf();
}

export async function synthesizeTextToSpeech(text: string) {
  const audioBuffer = await axios.post("/api/text-to-speech", { text: text });
  console.log(audioBuffer);

  const audioData = new Uint8Array(audioBuffer.data.audioContent.data);
  const blob = new Blob([audioData.buffer], { type: "audio/mp3" });
  new Audio(URL.createObjectURL(blob)).play();
}

export function timestampToDate(timestamp: Timestamp): Date {
  const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
  return new Date(milliseconds);
}

export const isFirestoreTimestamp = (value: any): boolean => {
  return value && typeof value === "object" && "seconds" in value && "nanoseconds" in value;
};

export async function convertTimestampToDate(secons: number, nanoseconds: number) {
  const milliseconds = secons * 1000 + Math.floor(nanoseconds / 1000000);
  return new Date(milliseconds);
}

export const addDaysToCreatedAt = (createdAt: any, days: any) => {
  return moment(createdAt).add(days, "days").format("MMMM Do YYYY");
};

export const returnEnrolmentDate = (createdAt: any) => {
  return moment(createdAt).format("MMMM Do YYYY");
};

export const stubbedData = {
  dashboardVideoWatched: true,
  personalityScore: {
    owl: 13,
    peacock: 8,
    dove: 13,
    eagle: 13,
  },
  firstName: "Major",
  uid: "3GQwMnuLYGdiyRLxgjVEIRMP2dc2",
  completionVideoWatched: true,
  completed: [
    {
      id: "d1d0b020-533f-4952-96c1-7f77eb808439",
      workshopCompleted: true,
      badgeCompleted: true,
      sections: [
        {
          pages: [
            {
              isLastPageInSection: false,
              id: "2cedf894-9635-4c63-9df8-137b1b251e45",
            },
            {
              id: "44975e11-c475-499d-8675-c547fb99e513",
              isLastPageInSection: false,
            },
            {
              id: "c91af325-daaf-4c32-95fb-0426467f3915",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "a9f090bb-ec3d-4582-8e4f-8becdaaab21c",
            },
            {
              id: "f136cefb-ec2f-4bf0-a49d-0ae4e36aa6da",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "df1ce0b3-97c0-47a6-b160-db312fa3e678",
            },
            {
              id: "f2839b7c-7ae3-4b25-bdf6-fa62c6f38eb1",
              isLastPageInSection: true,
            },
            {
              id: "b5d626e4-b7b5-4c40-8ab9-1148420c2c1c",
              isLastPageInSection: false,
            },
          ],
          sectionCompleted: true,
          id: "e5e3cd10-60cd-48d6-859d-dbc27e3de0de",
        },
        {
          pages: [
            {
              id: "0126ae71-d42c-450c-af26-6c7514fee7c8",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c7c5dc3a-164b-4439-8f37-f7ccdf793eff",
            },
            {
              id: "2c6da5cc-19e8-4f3c-9a69-dac20feeddf2",
              isLastPageInSection: false,
            },
            {
              id: "042150d3-525b-4edb-a61f-8dc233751ffb",
              isLastPageInSection: false,
            },
            {
              id: "23d4904f-a651-4895-9050-c941356ad21c",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "9a0dac4a-937b-4cac-adc3-43512e49421e",
            },
            {
              id: "013865e7-830d-40c6-a6fa-e4678f8bea41",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "b0ecad1d-4789-4a79-9cfe-c27ed1f261a9",
            },
            {
              isLastPageInSection: true,
              id: "62ab2396-271c-44ea-90ff-7caf3c12f2fe",
            },
          ],
          sectionCompleted: true,
          id: "7a2dc004-d89d-44b6-8a6d-c47535743225",
        },
        {
          sectionCompleted: true,
          id: "c06dce34-7ff7-4023-83cc-261209705229",
          pages: [
            {
              id: "1ec505ed-be39-4888-b898-04bfb3c4326f",
            },
            {
              isLastPageInSection: false,
              id: "203824ca-a7cf-4375-b00f-4b7fdd29716f",
            },
            {
              id: "f2906994-1f91-474e-a9b5-5489390d2322",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "24825ff1-1d69-45c2-a285-b1eff2ce683c",
            },
            {
              id: "37be526c-0879-4625-9c63-69758b47c7c8",
              isLastPageInSection: false,
            },
            {
              id: "b24f5dd3-67d5-4685-a0c3-a0fd20f3eb91",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "920064da-007c-4de8-a741-96ef81b17d45",
            },
            {
              isLastPageInSection: false,
              id: "d9a5d562-5a65-4fe1-a3be-6967642bfb33",
            },
            {
              isLastPageInSection: false,
              id: "841d42dd-b251-4451-860b-954369ba0b9b",
            },
            {
              id: "7375db31-1b22-492e-a211-7157e9fc43fe",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c9e62601-3eee-4834-a258-ec21ffe10063",
            },
            {
              isLastPageInSection: false,
              id: "a72ff1a6-4221-4cce-bf98-1a88a78b187f",
            },
            {
              isLastPageInSection: false,
              id: "c45fb91f-0b04-4f26-b5bd-040c3c046155",
            },
            {
              id: "09b1b77d-c1b3-4946-9b16-527758b93247",
              isLastPageInSection: false,
            },
            {
              id: "59f3e6e1-0e51-4e40-9d26-9d18a52d0e2c",
              isLastPageInSection: true,
            },
          ],
        },
        {
          pages: [
            {
              id: "dc9b1613-54e6-41f4-b5b5-ed45ead123c8",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "f57c8d27-fc57-424e-97c4-39a713ce427f",
            },
            {
              id: "fa868ede-adcd-4a94-9cf4-da027cef158e",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c7423fe5-7d1d-4792-99b1-4f5ac5b517e3",
            },
            {
              isLastPageInSection: false,
              id: "c027f1dd-e68d-45a6-b488-65f11330bbce",
            },
            {
              id: "b0e1cc0c-4fcb-4a00-87a1-2b592ed91d47",
              isLastPageInSection: true,
            },
            {
              id: "f907c14c-3fb3-481d-a69b-9d66fadb8220",
              isLastPageInSection: false,
            },
          ],
          sectionCompleted: true,
          id: "da9259ef-94cb-4583-8054-a1dc91ee071f",
        },
        {
          sectionCompleted: true,
          pages: [
            {
              id: "d2e08572-7e61-4b44-a822-fe981af757f8",
            },
            {
              isLastPageInSection: false,
              id: "67f878f4-aa7b-45e8-abfa-d6863d52a30d",
            },
            {
              isLastPageInSection: false,
              id: "538d3f0e-36f2-4b96-a7b1-3de9f928fd75",
            },
            {
              id: "b14191eb-8311-48f0-968d-e940b5d3a0f8",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "a2dccc82-3783-43c1-bca4-8e402bf3b432",
            },
            {
              id: "c5c76dac-aca7-443d-bf4a-3ea2338a10d5",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "896d5521-95df-4fb1-b9dd-c243f5656cc6",
            },
            {
              isLastPageInSection: true,
              id: "5ff9c089-0ec6-46c3-95c7-b9701a4ea191",
            },
          ],
          id: "c9c9e5a8-8125-4eb8-9b98-b2a7cd12cb04",
        },
      ],
    },
    {
      badgeCompleted: true,
      workshopCompleted: true,
      sections: [
        {
          sectionCompleted: true,
          id: "633ab357-b891-4a4b-beea-970b4ce07fcb",
          pages: [
            {
              isLastPageInSection: false,
              id: "27cff5da-5280-4160-9d84-e349dd0ed269",
            },
            {
              isLastPageInSection: true,
              id: "bf97a9dd-10ce-4697-9838-3d0b89ff14ef",
            },
          ],
        },
        {
          id: "75b442c2-5477-46d2-a2cd-cd8e887da349",
          sectionCompleted: true,
          pages: [
            {
              id: "706fe69b-35e8-48f6-9d4f-e35bb926a940",
            },
            {
              isLastPageInSection: false,
              id: "52c53ba7-da23-4892-aa2a-004d380f9938",
            },
            {
              id: "883621ab-c09c-4458-8b49-a50b8e05cf61",
              isLastPageInSection: false,
            },
            {
              id: "ee754fd6-f29b-4785-90dc-e8590d1e69ea",
              isLastPageInSection: false,
            },
            {
              id: "350b5db8-dbab-4ad3-9435-41c71320714e",
              isLastPageInSection: false,
            },
            {
              id: "6adb81c3-a310-474d-8bf3-153ae4aa282b",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "a7f28b7b-f383-4cd7-8bbd-65e41237158d",
            },
            {
              isLastPageInSection: false,
              id: "15a4b787-d406-4f25-9d55-c723b8bc9da7",
            },
            {
              id: "a655914b-4b47-412e-b386-6582dfd0597a",
              isLastPageInSection: false,
            },
            {
              id: "05200a3e-1119-492e-87c9-9d78c99a73b6",
              isLastPageInSection: false,
            },
            {
              id: "cc8b5cc6-a4cb-46ef-ae84-c949768d4f68",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "30b57201-3ce9-4823-bb85-eb47ab5b012f",
            },
          ],
        },
        {
          pages: [
            {
              id: "3b162185-240c-400a-9ed1-0cef11536808",
            },
            {
              isLastPageInSection: true,
              id: "bb2d7150-531e-4ce0-a0a8-cb7f5c9f02f6",
            },
            {
              isLastPageInSection: true,
              id: "6f5872f3-b4b6-4c40-8c35-5053db1f01fc",
            },
            {
              id: "1d0afe52-863d-4d36-9345-3a6710974390",
              isLastPageInSection: true,
            },
          ],
          sectionCompleted: true,
          id: "df23094b-30e3-4b73-b177-26645667cddc",
        },
        {
          sectionCompleted: true,
          pages: [
            {
              isLastPageInSection: false,
              id: "df9d3dd0-41ec-45a6-bd9c-b916d3493a90",
            },
            {
              isLastPageInSection: false,
              id: "32e36fbd-e93e-4cf2-acf0-96aed7a65cc7",
            },
            {
              isLastPageInSection: false,
              id: "a4716bcf-b4ea-443a-9992-2afbe7fab4e5",
            },
            {
              isLastPageInSection: false,
              id: "6a7b077d-ecef-470b-8c1a-9d7ae02440d6",
            },
            {
              id: "cce7bf43-78f8-410a-bb0a-5adfaeb8a36e",
              isLastPageInSection: false,
            },
            {
              id: "bb4bb716-2314-44d2-91bb-316a33afb486",
              isLastPageInSection: false,
            },
            {
              id: "5a6f84b5-5886-41f7-8106-3a7c91be5d1f",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "63d9d162-dca8-41c0-ab3d-57c247342338",
            },
            {
              isLastPageInSection: false,
              id: "87da05e0-8a96-4378-9871-783bfef24aee",
            },
          ],
          id: "6095f239-605b-422f-8800-6669a0191128",
        },
        {
          id: "3598c65a-71b2-4342-8b90-231ade0fb634",
          pages: [
            {
              id: "ba16db8d-90ea-416f-beb3-de0011d65248",
            },
            {
              id: "9b652f60-39b4-4b5a-9123-7ba7d179843c",
              isLastPageInSection: false,
            },
            {
              id: "80ba75b9-4b39-487b-8ced-bf7198b8d544",
              isLastPageInSection: false,
            },
            {
              id: "9e6fe488-8b4e-41fe-86d1-007f881c132f",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "2c1a8362-80a1-44a4-a5fd-e094b898dec0",
            },
            {
              isLastPageInSection: false,
              id: "cf1a3edc-6247-430e-9673-67de9b1ebe49",
            },
            {
              id: "405200d7-d13d-4629-8d55-ece6cf733de1",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "9d01c1cd-7c02-440b-a756-7aae1a2fade3",
            },
            {
              id: "c9b931bb-c892-4029-919b-031af4e73885",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "b6e52005-efcf-4702-8f01-9c6bf25eee01",
            },
          ],
          sectionCompleted: true,
        },
      ],
      id: "dc090e88-eb52-4722-8126-1f9c15e24c3c",
    },
    {
      workshopCompleted: true,
      sections: [
        {
          sectionCompleted: true,
          id: "bce6f7b0-4c94-4b64-98bb-47ac985d6f17",
          pages: [
            {
              isLastPageInSection: false,
              id: "f5031d1a-3f19-42ce-a595-d1dff2607488",
            },
            {
              isLastPageInSection: false,
              id: "604bfbbc-f894-4e5f-8e7b-8b1c048e28e2",
            },
            {
              isLastPageInSection: false,
              id: "24b3e01b-0c1b-4235-8c02-f76133f106cb",
            },
            {
              id: "0e349151-6642-4c39-ba6e-8704ae6c8e14",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "252c5698-447e-43ee-bde0-fa8f978a4c41",
            },
            {
              id: "1a3df2fc-74f9-4d59-8cf4-208fb91a1af0",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "9abf5aa1-dd30-489e-82f1-45b840ae165a",
            },
            {
              isLastPageInSection: true,
              id: "d7206c03-340a-4dde-8b56-e3ffb969044a",
            },
          ],
        },
        {
          pages: [
            {
              isLastPageInSection: false,
              id: "5683920b-fb63-4a4a-8ea7-1b408eae3f2b",
            },
            {
              id: "1e26f5d1-72e7-4e48-8e57-efd68445c4ad",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "4208cf88-5684-49e0-b418-5b96d96a4f07",
            },
            {
              isLastPageInSection: false,
              id: "a4a06bba-7749-4014-8eec-2eaa606a18af",
            },
            {
              isLastPageInSection: false,
              id: "9fc0000c-bd86-43ab-8ffd-011663f16211",
            },
            {
              isLastPageInSection: false,
              id: "d4d5341f-4473-47fc-a919-0ad458152fdf",
            },
            {
              isLastPageInSection: false,
              id: "01b07c00-17be-4908-8639-45c4acc9393a",
            },
            {
              isLastPageInSection: false,
              id: "a7ccca92-1b1c-4a0d-975f-83f3e3ff7d8f",
            },
            {
              isLastPageInSection: false,
              id: "c34f0f90-1c1a-46eb-bf79-ff0716aa2a8b",
            },
            {
              isLastPageInSection: false,
              id: "70774016-1269-4b4e-ada4-0bf0e8a19da8",
            },
            {
              id: "401324dd-b547-4367-a11c-17c356e4ff69",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "f0ebdde6-d007-41ad-b4ce-251c510c8dff",
            },
            {
              id: "74d8a148-3645-4e07-a95f-d2f9ff30e0b0",
              isLastPageInSection: false,
            },
            {
              id: "b03bd6a4-ce59-47c0-a9bc-0708cfa2a3c4",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "b7048813-e5e2-4bb1-b77a-8817599f7029",
            },
            {
              isLastPageInSection: false,
              id: "2d37c286-c186-494a-a4c1-c0de5fbb113d",
            },
            {
              id: "b194f030-bdac-49a1-8ce1-5163ea90792c",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "74319c2c-56aa-4be3-b1c2-26e26ca8f40a",
            },
          ],
          sectionCompleted: true,
          id: "93f47643-e977-42c1-8dfa-4aea1a961cc2",
        },
        {
          pages: [
            {
              id: "e9b5a758-81c5-43b4-9b90-78ef173a44ba",
            },
            {
              isLastPageInSection: false,
              id: "2828ffaa-70e8-4c40-8f39-fe7ffcff9be1",
            },
            {
              id: "639d098c-2cfd-4d76-aa07-e913e189b03c",
              isLastPageInSection: false,
            },
            {
              id: "03070ba0-37a9-449f-a1d1-c13c0fd18762",
              isLastPageInSection: false,
            },
            {
              id: "88d3f4f7-c1ca-4b9e-928c-3344424dd739",
              isLastPageInSection: false,
            },
            {
              id: "ac8ed99b-c924-4f10-a596-68b3a33e91c5",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "881a5990-fdb7-411e-a06d-31290c010699",
            },
            {
              isLastPageInSection: false,
              id: "e319caf2-7b5d-4986-bd3b-8f410389d49c",
            },
            {
              id: "a0d903b9-e64f-41f7-ab63-24a7a9d84e78",
              isLastPageInSection: false,
            },
            {
              id: "01fb6fee-79dd-4a09-94d2-a6aaf817431e",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "8a83be3f-c8c8-429e-b702-494f99433448",
            },
            {
              id: "7d2244c5-9a04-4c13-b27b-5bfe5a964869",
              isLastPageInSection: false,
            },
            {
              id: "f8c559d1-6132-488a-8e67-aced9340cd2b",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "dbef4951-b783-4f80-babd-44483554d5eb",
            },
          ],
          id: "666669ea-da53-43ff-8614-91b636acf5c4",
          sectionCompleted: true,
        },
        {
          id: "f6d1030e-b056-4472-b767-b52817790741",
          sectionCompleted: true,
          pages: [
            {
              id: "30fb5402-ad1d-4e6a-ad77-141870b2bccf",
              isLastPageInSection: false,
            },
            {
              id: "e26ce69b-5074-49a2-a6c8-5ffd02a9a376",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c3d9bbf3-28cf-4306-be93-b3d56acd8251",
            },
            {
              isLastPageInSection: false,
              id: "50ef0d2f-291a-49a2-aaa1-2b019e4c07c5",
            },
            {
              id: "c4832eb1-22d6-4ddd-b9c7-6dbf1cdf0ce0",
              isLastPageInSection: false,
            },
            {
              id: "fcfc9f98-abfe-4448-9e11-7f9a89e3c36d",
              isLastPageInSection: true,
            },
            {
              id: "76c0a162-2854-46d5-a1c3-02a80a9745d3",
              isLastPageInSection: false,
            },
          ],
        },
        {
          id: "de597f94-5374-41d5-a584-0f222cc72386",
          pages: [
            {
              id: "160ed775-6863-4940-8970-e276a6aa574f",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c95b7fb1-b4fc-43d7-b6c6-175290210bd8",
            },
            {
              isLastPageInSection: false,
              id: "36bde3f2-b939-49f9-bb73-250a2a48a9f0",
            },
            {
              isLastPageInSection: false,
              id: "857ed4bb-16e9-4628-85b8-9aba2fd2830b",
            },
            {
              id: "2194eff1-46ad-45e9-afd8-50b5adab561e",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "0bd99eaa-66be-42f7-956a-9d1989717809",
            },
            {
              id: "bf55184f-675b-4809-a9dc-efafb8d48f48",
              isLastPageInSection: false,
            },
            {
              id: "da5e2264-cdde-46f2-8cea-eae4e7fe960b",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "d02b83c5-09a6-4ce3-b2eb-51d5ade8dc59",
            },
            {
              id: "9c3c4970-d113-4676-bc19-94e6d5aafd3c",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "33c201a9-e824-425a-9d57-f5816080172c",
            },
            {
              id: "905c6b2c-e23a-4c3f-b75d-ee0b4816abdd",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "06cf874b-cf44-48ad-bada-bdb4a23c28f4",
            },
            {
              id: "1303b73c-5626-4a65-ac7b-f3d5dba8ba36",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "26a64e3d-9442-4dd3-94b6-64d918659b43",
            },
            {
              isLastPageInSection: false,
              id: "a2519dca-945d-4f87-979b-1abf222250ad",
            },
            {
              isLastPageInSection: false,
              id: "0fcd3f7b-41f0-45de-9137-1c5ce71dbf1d",
            },
            {
              id: "025fbf93-2839-41ce-8ab2-cf55666b45cb",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "c9f64171-199d-4466-8a02-3498573638c6",
            },
            {
              id: "f232150b-7c1c-456c-9fb9-e0c20e6b17ce",
              isLastPageInSection: false,
            },
            {
              id: "e551aed7-541d-4838-9204-feb7e68d2a05",
              isLastPageInSection: false,
            },
            {
              id: "6c025580-40da-4439-81b4-835fc46a79c4",
              isLastPageInSection: false,
            },
            {
              id: "47fc5900-db84-44ee-ac43-cce385ca1b7e",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "a9ece8e0-3751-422b-9ccb-1c1e54fc4989",
            },
            {
              id: "0b04fa68-486a-47b6-bb95-8507e4004471",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: true,
              id: "adc6481a-1512-4586-b911-9b125892a1be",
            },
          ],
          sectionCompleted: true,
        },
      ],
      badgeCompleted: false,
      id: "b946af3e-4517-4cdc-9e83-46ce5f23c6d5",
    },
    {
      id: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",

      sections: [
        {
          pages: [
            {
              id: "598c86ad-3c3a-4944-a8fa-ca77ae142c23",
            },
            {
              id: "4cc9ece6-fd6d-4442-87b5-0f036ed30cad",
              isLastPageInSection: false,
            },
            {
              id: "bfc3291e-6d73-4125-a8ee-3ac2faa24112",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "350b0400-01cc-442d-9d1a-68d38eaa23a3",
            },
            {
              isLastPageInSection: false,
              id: "c2b3386f-1fe9-451c-8360-8ff9a78a2d96",
            },
            {
              id: "d1a2817d-3d24-435f-bc08-29b5be599d8e",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "cc93a0e4-8df0-4b17-9392-7351c3b58b84",
            },
            {
              isLastPageInSection: false,
              id: "1355934a-648c-4d1f-8b77-a22f8bf4844d",
            },
            {
              isLastPageInSection: false,
              id: "1df0932a-127a-49e9-a8b3-302c2fff24b0",
            },
            {
              isLastPageInSection: false,
              id: "4fd6f6f7-fbe2-4e12-8afe-fcdce6dc477a",
            },
            {
              id: "ab8ba982-8e9d-4ee4-afcd-aef1da131446",
              isLastPageInSection: true,
            },
          ],
          sectionCompleted: true,
          id: "bba26017-85ab-4a25-acfa-7cb8b44de72a",
        },
        {
          sectionCompleted: true,
          pages: [
            {
              id: "29cdf188-852e-418c-a4b6-697ccf5d732a",
            },
            {
              isLastPageInSection: false,
              id: "75e8eefe-a006-4aab-bbfb-180af034bb75",
            },
            {
              isLastPageInSection: false,
              id: "8efe29c4-da49-43af-9d82-c4a9669d4161",
            },
            {
              isLastPageInSection: false,
              id: "e8bfb67b-0422-4b14-bf85-bb555b9af054",
            },
            {
              isLastPageInSection: false,
              id: "2475a5a3-3d50-4e9f-a012-e403b69678c2",
            },
            {
              id: "8d24da7b-0a61-42f8-874e-ea560b36e5d2",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "48241285-ddf1-44f5-bf35-46986070dcb6",
            },
            {
              id: "24cf1b2f-1697-4800-866c-b3a772e15855",
              isLastPageInSection: false,
            },
            {
              isLastPageInSection: false,
              id: "cc7614e6-59f3-41b3-9a32-930f8389e68d",
            },
            {
              id: "53414a0a-38f4-4f1e-9a48-fad742adcd88",
              isLastPageInSection: false,
            },
            {
              id: "993d91fa-74ff-4593-a675-d3f5f3c7973f",
              isLastPageInSection: false,
            },
            {
              id: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
              isLastPageInSection: false,
            },
            {
              id: "b68781fa-e10a-456f-a647-da874a2e6cfe",
              isLastPageInSection: true,
            },
          ],
          id: "346a1c67-0832-40e8-8aff-6d60ca59cd14",
        },
      ],
    },
  ],

  lastActive: {
    seconds: 1723269647,
    nanoseconds: 865000000,
  },
  lastName: "Tom",
  email: "majortom@groundcontrol.co.nz",
  displayName: null,
  createdDate: "2023-04-30T22:01:30.706Z",
  engagement: [
    {
      pageTitle: "personality-type-ben",
      activities: [
        {
          engagementType: "initial",
          type: "flipCard",
          pageID: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
          pageTitle: "personality-type-ben",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
        },
        {
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
          pageID: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
          engagementType: "initial",
          type: "quiz",
          uniqueKey: "677891cd3234",
          pageTitle: "personality-type-ben",
        },
        {
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageID: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
          type: "flipCard",
          engagementType: "interaction",
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
          pageTitle: "personality-type-ben",
        },
        {
          engagementType: "interaction",
          pageID: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
          uniqueKey: "677891cd3234",
          type: "quiz",
          pageTitle: "personality-type-ben",
        },
      ],
      workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
      pageID: "56aaaf30-65ec-4c0f-9cb6-1f11fc9cae76",
    },
    {
      activities: [
        {
          pageTitle: "munee-intro",
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
          pageID: "f925b2e0-f2cd-42f3-85fe-eed1b8730b4d",
          uniqueKey: "21ba5c5ffedb",
          engagementType: "initial",
          type: "carousel",
        },
        {
          pageID: "f925b2e0-f2cd-42f3-85fe-eed1b8730b4d",
          engagementType: "interaction",
          type: "carousel",
          uniqueKey: "21ba5c5ffedb",
          pageTitle: "munee-intro",
          workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
        },
      ],
      pageID: "f925b2e0-f2cd-42f3-85fe-eed1b8730b4d",
      pageTitle: "munee-intro",
      workshopID: "fb9009fc-35ec-4129-b93f-d7ec733f04e7",
    },
    {
      pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
      activities: [
        {
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          engagementType: "initial",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageTitle: "lifetime-cost",
          type: "flipCard",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          pageTitle: "lifetime-cost",
          type: "flipCard",
          uniqueKey: "854ff93711e01e0b8e38da241cf76bd3",
          engagementType: "initial",
        },
        {
          type: "quiz",
          uniqueKey: "677891cd3234",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          pageTitle: "lifetime-cost",
          engagementType: "initial",
        },
        {
          uniqueKey: "d261399776c39687b2fa4448cdfeafa5",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          engagementType: "initial",
          pageTitle: "lifetime-cost",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageTitle: "lifetime-cost",
          type: "flipCard",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          engagementType: "interaction",
        },
        {
          engagementType: "interaction",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          pageTitle: "lifetime-cost",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "flipCard",
          uniqueKey: "854ff93711e01e0b8e38da241cf76bd3",
        },
        {
          pageTitle: "lifetime-cost",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "677891cd3234",
          engagementType: "interaction",
        },
        {
          uniqueKey: "d261399776c39687b2fa4448cdfeafa5",
          type: "quiz",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "lifetime-cost",
          engagementType: "interaction",
        },
        {
          uniqueKey: "6cdb998bc788",
          pageTitle: "lifetime-cost",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "verticalDragDrop",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "lifetime-cost",
          pageID: "5e0e889d-902a-4028-b85d-e03feaaf93b8",
          type: "verticalDragDrop",
          uniqueKey: "23e8df74cdb6b0cfb71432677b5daeb3",
          engagementType: "initial",
        },
      ],
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "lifetime-cost",
    },
    {
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageID: "110d9091-edf1-4a16-b962-7dccc13d9224",
      pageTitle: "your-lifetime-costs",
      activities: [
        {
          pageID: "110d9091-edf1-4a16-b962-7dccc13d9224",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "verticalDragDrop",
          pageTitle: "your-lifetime-costs",
          uniqueKey: "6cdb998bc788",
        },
        {
          pageTitle: "your-lifetime-costs",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "110d9091-edf1-4a16-b962-7dccc13d9224",
          type: "verticalDragDrop",
          engagementType: "initial",
          uniqueKey: "23e8df74cdb6b0cfb71432677b5daeb3",
        },
        {
          pageID: "110d9091-edf1-4a16-b962-7dccc13d9224",
          type: "verticalDragDrop",
          pageTitle: "your-lifetime-costs",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          uniqueKey: "6cdb998bc788",
        },
        {
          engagementType: "interaction",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "23e8df74cdb6b0cfb71432677b5daeb3",
          pageTitle: "your-lifetime-costs",
          pageID: "110d9091-edf1-4a16-b962-7dccc13d9224",
          type: "verticalDragDrop",
        },
      ],
    },
    {
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "lifetime-cost-saved",
      activities: [
        {
          type: "carousel",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "0baf088a-05f0-4bbb-b2b1-cca9c55de671",
          uniqueKey: "4e94ca91caae",
          engagementType: "initial",
          pageTitle: "lifetime-cost-saved",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          type: "carousel",
          uniqueKey: "4e94ca91caae",
          pageTitle: "lifetime-cost-saved",
          pageID: "0baf088a-05f0-4bbb-b2b1-cca9c55de671",
        },
      ],
      pageID: "0baf088a-05f0-4bbb-b2b1-cca9c55de671",
    },
    {
      pageID: "e42de527-5ab2-4335-b16f-e04fefa78ab7",
      activities: [
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "d7fb79b15784",
          pageTitle: "what-is-a-budget",
          type: "horizontalDragDrop",
          pageID: "e42de527-5ab2-4335-b16f-e04fefa78ab7",
          engagementType: "initial",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          type: "horizontalDragDrop",
          uniqueKey: "d7fb79b15784",
          pageID: "e42de527-5ab2-4335-b16f-e04fefa78ab7",
          pageTitle: "what-is-a-budget",
        },
      ],
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "what-is-a-budget",
    },
    {
      activities: [
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "verticalDragDrop",
          pageTitle: "income-and-expenses",
          uniqueKey: "6cdb998bc788",
          pageID: "d5572573-5a9c-4c69-8012-fefe82d1d2c2",
          engagementType: "initial",
        },
        {
          pageID: "d5572573-5a9c-4c69-8012-fefe82d1d2c2",
          engagementType: "initial",
          pageTitle: "income-and-expenses",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          uniqueKey: "677891cd3234",
        },
        {
          pageID: "d5572573-5a9c-4c69-8012-fefe82d1d2c2",
          type: "verticalDragDrop",
          engagementType: "interaction",
          uniqueKey: "6cdb998bc788",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "income-and-expenses",
        },
        {
          pageTitle: "income-and-expenses",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "677891cd3234",
          engagementType: "interaction",
          pageID: "d5572573-5a9c-4c69-8012-fefe82d1d2c2",
        },
      ],
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "income-and-expenses",
      pageID: "d5572573-5a9c-4c69-8012-fefe82d1d2c2",
    },
    {
      activities: [
        {
          uniqueKey: "43ca3a0b16e7",
          pageID: "a412bb16-f59f-472b-b80a-043682f5fea8",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "budget-template",
          engagementType: "initial",
        },
        {
          pageID: "a412bb16-f59f-472b-b80a-043682f5fea8",
          pageTitle: "budget-template",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "hotspot",
          engagementType: "initial",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          pageID: "a412bb16-f59f-472b-b80a-043682f5fea8",
          uniqueKey: "43ca3a0b16e7",
          pageTitle: "budget-template",
          type: "quiz",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "a412bb16-f59f-472b-b80a-043682f5fea8",
          type: "hotspot",
          pageTitle: "budget-template",
          engagementType: "interaction",
        },
      ],
      pageTitle: "budget-template",
      pageID: "a412bb16-f59f-472b-b80a-043682f5fea8",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
    },
    {
      pageTitle: "how-to-create-a-budget",
      pageID: "80900315-08f2-48a7-90b4-6182c50eee19",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      activities: [
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageID: "80900315-08f2-48a7-90b4-6182c50eee19",
          pageTitle: "how-to-create-a-budget",
          engagementType: "initial",
          type: "flipCard",
        },
        {
          type: "flipCard",
          engagementType: "interaction",
          pageTitle: "how-to-create-a-budget",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageID: "80900315-08f2-48a7-90b4-6182c50eee19",
        },
      ],
    },
    {
      activities: [
        {
          pageID: "bccff0f7-7e8f-473e-a539-48823ff08589",
          engagementType: "initial",
          uniqueKey: "16e708d8348d",
          type: "accordion",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-1-make-a-list",
        },
        {
          engagementType: "interaction",
          pageID: "bccff0f7-7e8f-473e-a539-48823ff08589",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "935e2f390101",
          type: "accordion",
          pageTitle: "step-1-make-a-list",
        },
      ],
      pageTitle: "step-1-make-a-list",
      pageID: "bccff0f7-7e8f-473e-a539-48823ff08589",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
    },
    {
      activities: [
        {
          pageTitle: "step-2-income",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
          engagementType: "initial",
          uniqueKey: "",
          type: "accordion",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "initial",
          uniqueKey: "677891cd3234",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
          pageTitle: "step-2-income",
        },
        {
          uniqueKey: "1c0acd556ba9d81b9b9e4231f27dff51",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-2-income",
          type: "quiz",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
        },
        {
          engagementType: "interaction",
          pageTitle: "step-2-income",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
          type: "accordion",
          uniqueKey: "8d83853f2d1a",
        },
        {
          uniqueKey: "677891cd3234",
          pageTitle: "step-2-income",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          type: "quiz",
        },
        {
          engagementType: "interaction",
          uniqueKey: "1c0acd556ba9d81b9b9e4231f27dff51",
          type: "quiz",
          pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
          pageTitle: "step-2-income",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
      ],
      pageID: "f604b808-22fa-4031-8f54-35fd51715e7c",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "step-2-income",
    },
    {
      pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "step-3-expenses",
      activities: [
        {
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "accordion",
          pageTitle: "step-3-expenses",
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          uniqueKey: "",
        },
        {
          engagementType: "initial",
          type: "quiz",
          uniqueKey: "677891cd3234",
          pageTitle: "step-3-expenses",
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          type: "quiz",
          pageTitle: "step-3-expenses",
          engagementType: "initial",
          uniqueKey: "1c0acd556ba9d81b9b9e4231f27dff51",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          pageTitle: "step-3-expenses",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "8d83853f2d1a",
          engagementType: "interaction",
          type: "accordion",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-3-expenses",
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          type: "quiz",
          uniqueKey: "677891cd3234",
          engagementType: "interaction",
        },
        {
          uniqueKey: "1c0acd556ba9d81b9b9e4231f27dff51",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          pageID: "81496ef7-52e7-4873-bc0c-010cb2fd3cbb",
          pageTitle: "step-3-expenses",
          engagementType: "interaction",
        },
      ],
    },
    {
      pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
      pageTitle: "step-4-update",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      activities: [
        {
          engagementType: "initial",
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
          pageTitle: "step-4-update",
          uniqueKey: "677891cd3234",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
        },
        {
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
          uniqueKey: "c186d5563145464f2e6224c879444908",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-4-update",
          engagementType: "initial",
          type: "quiz",
        },
        {
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          uniqueKey: "75b8e089e263dfb79725258d1363be04",
          pageTitle: "step-4-update",
          engagementType: "initial",
        },
        {
          engagementType: "interaction",
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
          type: "quiz",
          pageTitle: "step-4-update",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "677891cd3234",
        },
        {
          uniqueKey: "c186d5563145464f2e6224c879444908",
          pageTitle: "step-4-update",
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
          engagementType: "interaction",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          type: "quiz",
          pageTitle: "step-4-update",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          uniqueKey: "75b8e089e263dfb79725258d1363be04",
          pageID: "237fe398-3fef-42d5-ad05-de203de5e365",
        },
      ],
    },
    {
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      activities: [
        {
          uniqueKey: "677891cd3234",
          type: "quiz",
          pageID: "33df4410-9f34-478d-94e0-be9d2689c8e3",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "miscellaneous-expenses",
        },
        {
          engagementType: "interaction",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          pageID: "33df4410-9f34-478d-94e0-be9d2689c8e3",
          uniqueKey: "677891cd3234",
          pageTitle: "miscellaneous-expenses",
        },
      ],
      pageID: "33df4410-9f34-478d-94e0-be9d2689c8e3",
      pageTitle: "miscellaneous-expenses",
    },
    {
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "step-5-types-of-expenses",
      activities: [
        {
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "536134de-8678-4e08-aa6e-4ea4f8d7c647",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
          pageTitle: "step-5-types-of-expenses",
        },
        {
          engagementType: "initial",
          uniqueKey: "6cdb998bc788",
          pageTitle: "step-5-types-of-expenses",
          type: "verticalDragDrop",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "536134de-8678-4e08-aa6e-4ea4f8d7c647",
        },
        {
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "step-5-types-of-expenses",
          engagementType: "interaction",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "flipCard",
          pageID: "536134de-8678-4e08-aa6e-4ea4f8d7c647",
        },
        {
          engagementType: "interaction",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "verticalDragDrop",
          uniqueKey: "6cdb998bc788",
          pageTitle: "step-5-types-of-expenses",
          pageID: "536134de-8678-4e08-aa6e-4ea4f8d7c647",
        },
      ],
      pageID: "536134de-8678-4e08-aa6e-4ea4f8d7c647",
    },
    {
      activities: [
        {
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "interaction",
          type: "flipCard",
          pageTitle: "step-7-monitor-your-budget",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "hotspot",
          engagementType: "interaction",
          pageTitle: "step-7-monitor-your-budget",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
        },
        {
          pageTitle: "step-7-monitor-your-budget",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "flipCard",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
        },
        {
          pageTitle: "step-7-monitor-your-budget",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
          engagementType: "initial",
          type: "hotspot",
        },
        {
          engagementType: "initial",
          uniqueKey: "677891cd3234",
          type: "quiz",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
          pageTitle: "step-7-monitor-your-budget",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          uniqueKey: "698d36dc1a17044dd2850c15afd7fc98",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
          engagementType: "initial",
          pageTitle: "step-7-monitor-your-budget",
          type: "quiz",
        },
        {
          pageTitle: "step-7-monitor-your-budget",
          engagementType: "initial",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "dfecf87796a87651d45ca77cabe8955d",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
        },
        {
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-7-monitor-your-budget",
          engagementType: "initial",
          uniqueKey: "92092aa3d8e051d29d750565acea6799",
          pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
        },
      ],
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "step-7-monitor-your-budget",
      pageID: "5606f773-6992-471f-96cd-42f34c5ccbd3",
    },
    {
      pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      pageTitle: "step-7-monitor-your-budget-activity",
      activities: [
        {
          engagementType: "interaction",
          pageTitle: "step-7-monitor-your-budget-activity",
          uniqueKey: "677891cd3234",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
        },
        {
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          pageTitle: "step-7-monitor-your-budget-activity",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          uniqueKey: "698d36dc1a17044dd2850c15afd7fc98",
          engagementType: "interaction",
        },
        {
          engagementType: "interaction",
          type: "quiz",
          uniqueKey: "dfecf87796a87651d45ca77cabe8955d",
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "step-7-monitor-your-budget-activity",
        },
        {
          type: "quiz",
          uniqueKey: "92092aa3d8e051d29d750565acea6799",
          engagementType: "interaction",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          pageTitle: "step-7-monitor-your-budget-activity",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          pageTitle: "step-7-monitor-your-budget-activity",
          uniqueKey: "677891cd3234",
          engagementType: "initial",
        },
        {
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          uniqueKey: "698d36dc1a17044dd2850c15afd7fc98",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "initial",
          pageTitle: "step-7-monitor-your-budget-activity",
          type: "quiz",
        },
        {
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "dfecf87796a87651d45ca77cabe8955d",
          type: "quiz",
          pageTitle: "step-7-monitor-your-budget-activity",
          engagementType: "initial",
        },
        {
          pageTitle: "step-7-monitor-your-budget-activity",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "quiz",
          pageID: "7f6b968a-9b48-4136-9545-13d13f5c8b2d",
          uniqueKey: "92092aa3d8e051d29d750565acea6799",
        },
      ],
    },
    {
      pageTitle: "step-8-reduce-your-expenses",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
      activities: [
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "677891cd3234",
          pageTitle: "step-8-reduce-your-expenses",
          engagementType: "initial",
          type: "quiz",
          pageID: "cff4c513-21d6-4ad7-9886-1c24d82c2cab",
        },
        {
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
          pageID: "cff4c513-21d6-4ad7-9886-1c24d82c2cab",
          pageTitle: "step-8-reduce-your-expenses",
        },
        {
          engagementType: "interaction",
          pageID: "cff4c513-21d6-4ad7-9886-1c24d82c2cab",
          type: "quiz",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "677891cd3234",
          pageTitle: "step-8-reduce-your-expenses",
        },
        {
          type: "flipCard",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "step-8-reduce-your-expenses",
          pageID: "cff4c513-21d6-4ad7-9886-1c24d82c2cab",
          engagementType: "interaction",
        },
      ],
      pageID: "cff4c513-21d6-4ad7-9886-1c24d82c2cab",
    },
    {
      pageTitle: "8-steps-recap",
      activities: [
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          uniqueKey: "2a6a2e31bce6",
          type: "flipCard",
          pageTitle: "8-steps-recap",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
          engagementType: "initial",
        },
        {
          pageTitle: "8-steps-recap",
          type: "flipCard",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          engagementType: "initial",
          uniqueKey: "15cc353d53abd6b2128c18766bbf9ddf",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
        },
        {
          type: "flipCard",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
          pageTitle: "8-steps-recap",
          uniqueKey: "13d7f3b9316a20402c65efeedbb5d4b3",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          uniqueKey: "724292daedca55f0482b0d14bbf27cfb",
          pageTitle: "8-steps-recap",
          type: "flipCard",
          engagementType: "initial",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
        },
        {
          uniqueKey: "2a6a2e31bce6",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
          type: "flipCard",
          engagementType: "interaction",
          pageTitle: "8-steps-recap",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "8-steps-recap",
          uniqueKey: "15cc353d53abd6b2128c18766bbf9ddf",
          engagementType: "interaction",
          type: "flipCard",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
        },
        {
          uniqueKey: "13d7f3b9316a20402c65efeedbb5d4b3",
          engagementType: "interaction",
          type: "flipCard",
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          pageTitle: "8-steps-recap",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
        },
        {
          workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
          type: "flipCard",
          pageTitle: "8-steps-recap",
          pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
          engagementType: "interaction",
          uniqueKey: "724292daedca55f0482b0d14bbf27cfb",
        },
      ],
      pageID: "3652f4e1-f698-4bc1-a5da-d005e2d6c893",
      workshopID: "54af7658-7943-4c23-8c87-8f971b929e23",
    },
    {
      activities: [
        {
          type: "flipCard",
          pageID: "98cd8053-af5a-4fab-b324-cd4a249999fa",
          engagementType: "initial",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
          pageTitle: "horror-s7",
        },
        {
          type: "flipCard",
          pageTitle: "horror-s7",
          pageID: "98cd8053-af5a-4fab-b324-cd4a249999fa",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "interaction",
          uniqueKey: "32872cb48a4236328a527aa5f9ee2986",
        },
      ],
      pageTitle: "horror-s7",
      pageID: "98cd8053-af5a-4fab-b324-cd4a249999fa",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
    },
    {
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "horror-s11",
      pageID: "33c5ad02-0a15-4c9d-8d6b-e4a4913fb4cd",
      activities: [
        {
          type: "flipCard",
          engagementType: "initial",
          uniqueKey: "2a6a2e31bce6",
          pageTitle: "horror-s11",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "33c5ad02-0a15-4c9d-8d6b-e4a4913fb4cd",
        },
        {
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "horror-s11",
          type: "flipCard",
          uniqueKey: "2a6a2e31bce6",
          pageID: "33c5ad02-0a15-4c9d-8d6b-e4a4913fb4cd",
        },
      ],
    },
    {
      activities: [
        {
          engagementType: "initial",
          pageID: "11d373bd-62a5-46fb-ae39-942655728246",
          uniqueKey: "677891cd3234",
          pageTitle: "biggest-thing-youll-save-for",
          type: "quiz",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          uniqueKey: "677891cd3234",
          type: "quiz",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "11d373bd-62a5-46fb-ae39-942655728246",
          pageTitle: "biggest-thing-youll-save-for",
          engagementType: "interaction",
        },
      ],
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "biggest-thing-youll-save-for",
      pageID: "11d373bd-62a5-46fb-ae39-942655728246",
    },
    {
      pageTitle: "what-does-retirement-mean",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      activities: [
        {
          pageTitle: "what-does-retirement-mean",
          type: "flipCard",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "0691c9f8-4db4-4cc9-b630-eaa55455e8d6",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          engagementType: "initial",
        },
        {
          pageID: "0691c9f8-4db4-4cc9-b630-eaa55455e8d6",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "what-does-retirement-mean",
          type: "flipCard",
          engagementType: "interaction",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
        },
      ],
      pageID: "0691c9f8-4db4-4cc9-b630-eaa55455e8d6",
    },
    {
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "financial-freedom-question",
      activities: [
        {
          uniqueKey: "49c5dbde8b86",
          pageID: "44e678f3-dd4a-4d7b-aae8-73443d974a86",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "financial-freedom-question",
          engagementType: "initial",
          type: "quiz",
        },
        {
          type: "quiz",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "financial-freedom-question",
          engagementType: "initial",
          uniqueKey: "quiz",
          pageID: "44e678f3-dd4a-4d7b-aae8-73443d974a86",
        },
        {
          engagementType: "interaction",
          type: "quiz",
          uniqueKey: "49c5dbde8b86",
          pageTitle: "financial-freedom-question",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "44e678f3-dd4a-4d7b-aae8-73443d974a86",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "44e678f3-dd4a-4d7b-aae8-73443d974a86",
          type: "quiz",
          uniqueKey: "quiz",
          pageTitle: "financial-freedom-question",
          engagementType: "interaction",
        },
      ],
      pageID: "44e678f3-dd4a-4d7b-aae8-73443d974a86",
    },
    {
      pageTitle: "financial-freedom-ladder",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageID: "142618d2-a280-4ceb-8460-f04340d0953b",
      activities: [
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "142618d2-a280-4ceb-8460-f04340d0953b",
          pageTitle: "financial-freedom-ladder",
          engagementType: "initial",
          type: "hotspot",
        },
        {
          engagementType: "interaction",
          type: "hotspot",
          pageID: "142618d2-a280-4ceb-8460-f04340d0953b",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "financial-freedom-ladder",
        },
      ],
    },
    {
      pageTitle: "superannuation",
      pageID: "6e30850a-e181-4cc3-853f-f44cda0a178e",
      activities: [
        {
          pageTitle: "superannuation",
          uniqueKey: "49c5dbde8b86",
          type: "quiz",
          engagementType: "initial",
          pageID: "6e30850a-e181-4cc3-853f-f44cda0a178e",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          pageTitle: "superannuation",
          uniqueKey: "49c5dbde8b86",
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "6e30850a-e181-4cc3-853f-f44cda0a178e",
          type: "quiz",
        },
        {
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageID: "6e30850a-e181-4cc3-853f-f44cda0a178e",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "flipCard",
          pageTitle: "superannuation",
          engagementType: "initial",
        },
      ],
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
    },
    {
      activities: [
        {
          pageTitle: "superannuation-amounts",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          engagementType: "initial",
          type: "flipCard",
          pageID: "5d98fba9-c243-4464-893d-5eb2cbebb66e",
        },
        {
          pageID: "5d98fba9-c243-4464-893d-5eb2cbebb66e",
          pageTitle: "superannuation-amounts",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "flipCard",
        },
      ],
      pageID: "5d98fba9-c243-4464-893d-5eb2cbebb66e",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "superannuation-amounts",
    },
    {
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "what-is-kiwisaver",
      activities: [
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "what-is-kiwisaver",
          pageID: "18f13029-0af4-4bbc-87c3-ddca8d5a771e",
          type: "flipCard",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          engagementType: "initial",
        },
        {
          pageTitle: "what-is-kiwisaver",
          engagementType: "initial",
          type: "quiz",
          uniqueKey: "677891cd3234",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "18f13029-0af4-4bbc-87c3-ddca8d5a771e",
        },
        {
          pageID: "18f13029-0af4-4bbc-87c3-ddca8d5a771e",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "flipCard",
          engagementType: "interaction",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "what-is-kiwisaver",
        },
        {
          uniqueKey: "677891cd3234",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "18f13029-0af4-4bbc-87c3-ddca8d5a771e",
          engagementType: "interaction",
          type: "quiz",
          pageTitle: "what-is-kiwisaver",
        },
      ],
      pageID: "18f13029-0af4-4bbc-87c3-ddca8d5a771e",
    },
    {
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageID: "cbd597fc-1c31-4323-a366-d3b76cebc865",
      activities: [
        {
          pageID: "cbd597fc-1c31-4323-a366-d3b76cebc865",
          type: "verticalDragDrop",
          pageTitle: "how-to-get-money-out-of-kiwisaver",
          engagementType: "initial",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "6cdb998bc788",
        },
        {
          pageTitle: "how-to-get-money-out-of-kiwisaver",
          uniqueKey: "6cdb998bc788",
          type: "verticalDragDrop",
          pageID: "cbd597fc-1c31-4323-a366-d3b76cebc865",
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
      ],
      pageTitle: "how-to-get-money-out-of-kiwisaver",
    },
    {
      activities: [
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "7e8d1ab0d9fd",
          pageID: "1706cf86-cb49-40fa-8878-a2dbb0558364",
          type: "quiz",
          engagementType: "initial",
          pageTitle: "how-to-sign-up-for-kiwisaver",
        },
        {
          pageTitle: "how-to-sign-up-for-kiwisaver",
          uniqueKey: "7e8d1ab0d9fd",
          pageID: "1706cf86-cb49-40fa-8878-a2dbb0558364",
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "quiz",
        },
      ],
      pageID: "1706cf86-cb49-40fa-8878-a2dbb0558364",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "how-to-sign-up-for-kiwisaver",
    },
    {
      pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageTitle: "kiwisaver-cost-of-waiting",
      activities: [
        {
          engagementType: "initial",
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          pageTitle: "kiwisaver-cost-of-waiting",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "carousel",
          uniqueKey: "4e94ca91caae",
        },
        {
          pageTitle: "kiwisaver-cost-of-waiting",
          type: "carousel",
          uniqueKey: "3cdd41b99aaded419899da5fdb9a5aa5",
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          engagementType: "initial",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "carousel",
          engagementType: "initial",
          uniqueKey: "bf0c15cfc28048a2a028b77d64092d03",
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          pageTitle: "kiwisaver-cost-of-waiting",
        },
        {
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          uniqueKey: "4e94ca91caae",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "carousel",
          pageTitle: "kiwisaver-cost-of-waiting",
          engagementType: "interaction",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "carousel",
          uniqueKey: "3cdd41b99aaded419899da5fdb9a5aa5",
          engagementType: "interaction",
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          pageTitle: "kiwisaver-cost-of-waiting",
        },
        {
          pageID: "1fa2396c-00c7-4b49-9656-3456c4a039c0",
          type: "carousel",
          engagementType: "interaction",
          pageTitle: "kiwisaver-cost-of-waiting",
          uniqueKey: "bf0c15cfc28048a2a028b77d64092d03",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
      ],
    },
    {
      pageTitle: "sign-up-for-kiwisaver",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageID: "bc1b7949-6f3b-499d-8ee9-545affdd2fbb",
      activities: [
        {
          type: "flipCard",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "sign-up-for-kiwisaver",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "initial",
          pageID: "bc1b7949-6f3b-499d-8ee9-545affdd2fbb",
        },
        {
          pageID: "bc1b7949-6f3b-499d-8ee9-545affdd2fbb",
          type: "flipCard",
          pageTitle: "sign-up-for-kiwisaver",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "interaction",
        },
      ],
    },
    {
      activities: [
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "49c5dbde8b86",
          pageTitle: "compound-interest",
          pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
          engagementType: "initial",
          type: "quiz",
        },
        {
          engagementType: "initial",
          type: "flipCard",
          uniqueKey: "2a6a2e31bce6",
          pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "compound-interest",
        },
        {
          type: "carousel",
          engagementType: "initial",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "75a26c07bc0298acbb3b8ee043fb41fa",
          pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
          pageTitle: "compound-interest",
        },
        {
          pageTitle: "compound-interest",
          type: "quiz",
          engagementType: "interaction",
          uniqueKey: "49c5dbde8b86",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "interaction",
          uniqueKey: "2a6a2e31bce6",
          pageTitle: "compound-interest",
          pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
          type: "flipCard",
        },
      ],
      pageTitle: "compound-interest",
      pageID: "4bcf153b-33fb-4a50-9e23-3fee4d7b1cbd",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
    },
    {
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      activities: [
        {
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          type: "quiz",
          engagementType: "interaction",
          uniqueKey: "77bc22c5e303a3b2631976bbf04e7d77",
          pageTitle: "inflation",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          uniqueKey: "c46f01a317066ecb6f43cee647000816",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          engagementType: "interaction",
          pageTitle: "inflation",
          type: "quiz",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "inflation",
          uniqueKey: "26ab89024536b4f698bf996fec31f72a",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          engagementType: "interaction",
          type: "flipCard",
        },
        {
          engagementType: "interaction",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "inflation",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
        },
        {
          type: "quiz",
          engagementType: "initial",
          uniqueKey: "77bc22c5e303a3b2631976bbf04e7d77",
          pageTitle: "inflation",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          uniqueKey: "c46f01a317066ecb6f43cee647000816",
          engagementType: "initial",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          pageTitle: "inflation",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "quiz",
        },
        {
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          engagementType: "initial",
          uniqueKey: "26ab89024536b4f698bf996fec31f72a",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "inflation",
          type: "flipCard",
        },
        {
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
          pageTitle: "inflation",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "initial",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
        },
        {
          engagementType: "initial",
          uniqueKey: "677891cd3234",
          pageTitle: "inflation",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          type: "quiz",
        },
        {
          engagementType: "initial",
          type: "quiz",
          pageTitle: "inflation",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          uniqueKey: "26fa7fc8b9bb9f5b289ee033a827fc4c",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
        },
        {
          pageTitle: "inflation",
          uniqueKey: "3e22f2790675988297cdd6a88f0150ce",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "initial",
          pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
          type: "quiz",
        },
      ],
      pageID: "c85fb7af-95eb-462e-a5bb-63974be8b14a",
      pageTitle: "inflation",
    },
    {
      pageTitle: "compound-interest-vs-inflation",
      activities: [
        {
          type: "quiz",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "677891cd3234",
          engagementType: "initial",
          pageTitle: "compound-interest-vs-inflation",
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "compound-interest-vs-inflation",
          type: "quiz",
          uniqueKey: "26fa7fc8b9bb9f5b289ee033a827fc4c",
          engagementType: "initial",
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
        },
        {
          engagementType: "initial",
          type: "quiz",
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
          pageTitle: "compound-interest-vs-inflation",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "3e22f2790675988297cdd6a88f0150ce",
        },
        {
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
          engagementType: "interaction",
          type: "quiz",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "677891cd3234",
          pageTitle: "compound-interest-vs-inflation",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
          engagementType: "interaction",
          pageTitle: "compound-interest-vs-inflation",
          uniqueKey: "26fa7fc8b9bb9f5b289ee033a827fc4c",
          type: "quiz",
        },
        {
          type: "quiz",
          uniqueKey: "3e22f2790675988297cdd6a88f0150ce",
          pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          engagementType: "interaction",
          pageTitle: "compound-interest-vs-inflation",
        },
      ],
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
      pageID: "d5c58bd4-4b54-4376-b2ee-bac18604157a",
    },
    {
      pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
      activities: [
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "how-to-save-money",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
          engagementType: "initial",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "how-to-save-money",
          engagementType: "initial",
          type: "flipCard",
          uniqueKey: "3a958d9d2af689e4e28ac598349b51cf",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
        },
        {
          type: "flipCard",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "how-to-save-money",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
          engagementType: "interaction",
        },
        {
          uniqueKey: "3a958d9d2af689e4e28ac598349b51cf",
          pageTitle: "how-to-save-money",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          type: "flipCard",
          engagementType: "interaction",
        },
      ],
      pageTitle: "how-to-save-money",
      workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
    },
    {
      activities: [
        {
          uniqueKey: "quiz",
          workshopID: "d1d0b020-533f-4952-96c1-7f77eb808439",
          engagementType: "initial",
          pageID: "2cedf894-9635-4c63-9df8-137b1b251e45",
          pageTitle: "social-poll-question-who-are-you",
          type: "quiz",
        },
        {
          uniqueKey: "quiz",
          workshopID: "d1d0b020-533f-4952-96c1-7f77eb808439",
          engagementType: "interaction",
          pageID: "2cedf894-9635-4c63-9df8-137b1b251e45",
          pageTitle: "social-poll-question-who-are-you",
          type: "quiz",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "how-to-save-money",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          type: "flipCard",
          engagementType: "initial",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
        },
        {
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          pageTitle: "how-to-save-money",
          engagementType: "initial",
          type: "flipCard",
          uniqueKey: "3a958d9d2af689e4e28ac598349b51cf",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
        },
        {
          type: "flipCard",
          workshopID: "10725eea-5aa7-4200-8527-1661cd092cf3",
          uniqueKey: "92f0a817c9749b4d71ef1f6a4bd2415a",
          pageTitle: "how-to-save-money",
          pageID: "b9b95b20-f165-4624-a1f3-691d0219f381",
          engagementType: "interaction",
        },
      ],
      workshopID: "d1d0b020-533f-4952-96c1-7f77eb808439",
      pageID: "2cedf894-9635-4c63-9df8-137b1b251e45",
      pageTitle: "social-poll-question-who-are-you",
    },
  ],
};
